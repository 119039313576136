import React from 'react';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import Services from './Services';
import WhoUs from './WhoUs';

function Main(props) {
    return (
        <div >

            <WhoUs />
            <Services />
            <AboutUs />
            <ContactUs />
        </div>
    );
}

export default Main;