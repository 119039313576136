import React from 'react';

function AboutUs(props) {
    return (
        //     <!--==========================
        //     About Us Section
        //   ============================-->
        <section id="aboutus">
            <div className="container">



                <div className="row about-container">



                    <div className="col content order-2 wow fadeInUp">
                        <header className="section-header">
                            <h3><span className="style1">About</span> <span className="style2">US</span></h3>
                        </header>

                        <p>This website is operated by India Web Services. Throughout the site, the terms “we”, “us” and “our” refer to
                            India Web Services. India Web Services offers this website, including all information, tools and services
                            available from this site to you, the
                            user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here. By
                            visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be
                            bound by the following terms and
                            conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies
                            referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site,
                            including without limitation
                            users who are browsers, vendors, customers, merchants, and/ or contributors of content. Please read
                            these Terms of Service carefully before accessing or using our website. By accessing or using any part
                            of the site, you agree to be bound
                            by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then
                            you may not access the website or use any services. If these Terms of Service are considered an offer,
                            acceptance is expressly limited
                            to these Terms of Service.

                        </p>

                    </div>


                </div>

            </div>
        </section>
        //<!-- #about -->
    );
}

export default AboutUs;