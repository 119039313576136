import React from 'react';
import { Link } from 'react-router-dom';

function PrivacyPolicy(props) {
    return (

        <section id="about" className="clearfix">
            <div className="container">



                <div className="row about-container">
                    <div className="col content order-2 wow fadeInUp">
                        <header className="section-header">
                            <h3><span className="style1">Privacy</span> <span className="style2">Policy</span></h3>
                        </header>



                        <center>





                            <p>This Privacy Policy describes how your personal information is collected, used, and shared when you
                                visit or make a purchase from www.ispadbin.in.</p>


                        </center>

                        <h3>PERSONAL INFORMATION WE COLLECT</h3>


                        <p style={{ wordWrap: "break-word" }} >When you visit the Site, we automatically collect certain information
                            about your device, including information about your web browser, IP address, time zone, and some of the
                            cookies that are installed on your device. Additionally, as you browse
                            the Site, we collect information about the individual web pages or products that you view, what websites
                            or search terms referred you to the Site, and information about how you interact with the Site.
                        </p>
                        <p> When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device
                            Information and Order Information.
                        </p>

                        <h3>HOW DO WE USE YOUR PERSONAL INFORMATION?</h3>

                        <p> We use the Order Information that we collect generally to fulfill any orders placed through the Site
                            (including processing your payment information, arranging for shipping, and providing you with invoices
                            and/or order confirmations).<br /> Additionally,
                            we use this Order Information to:
                            <br />- Communicate with you.
                            <br /> - Screen our orders for potential risk or fraud,
                            <br />- And when in line with the preferences you have shared with us, provide you with information or
                            advertising relating to our products or services.
                        </p>


                        <h3>BEHAVIOURAL ADVERTISING
                        </h3>
                        <p>As described above, we use your Personal Information to provide you with targeted advertisements or
                            marketing communications we believe may be of interest to you.</p>

                        <h3> DO NOT TRACK
                        </h3>

                        <p>Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track
                            signal from your browser.</p>


                        <h3>YOUR RIGHTS
                        </h3>
                        <p>If you are a European resident, you have the right to access personal information we hold about you and
                            to ask that your personal information be corrected, updated, or deleted. If you would like to exercise
                            this right, please contact us through
                            the contact information below.

                        </p>

                        <p>Additionally, if you are a European resident we note that we are processing your information in order to
                            fulfill contracts we might have with you (for example if you make an order through the Site), or
                            otherwise to pursue our legitimate business
                            interests listed above. Additionally, please note that your information will be transferred outside of
                            Europe, including to Canada and the United States.
                        </p>

                        <h3>DATA RETENTION
                        </h3>
                        <p>When you place an order through the Site, we will maintain your Order Information for our records unless
                            and until you ask us to delete this information.
                        </p>

                        <h3>CHANGES</h3>
                        <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our
                            practices or for other operational, legal or regulatory reasons.</p>
                        <h3>
                            CONTACT US</h3>
                        <p>For more information about our privacy practices, if you have questions, or if you would like to make a
                            complaint, please contact us by email at info@ispadbin.in or
                            <Link to="/#contact"> Click here</Link>
                        </p>

                        <h3>Refund Policy</h3>
                        <p>No Refunds.
                        </p>
                        &nbsp;


                    </div>

                </div>
            </div>

        </section>

        //<!-- #Privacy Policy -->






    );
}

export default PrivacyPolicy;