import React from 'react';


function Header(props) {
    return (
        // <!--==========================
        // Header
        // ============================-->
        <header id="header" className="fixed-top">
            <div className="container">

                <div className="logo">
                    <a className="scrollto">
                        <img src={process.env.PUBLIC_URL + "assets/img/logo.png"} alt="" className="img-fluid" /></a>

                </div>

                <div className="log-in">
                    <a href="http://app.ispadbin.in/">
                        <img src={process.env.PUBLIC_URL + "assets/img/log-in.png"} alt="" className="img-fluid" />
                    </a>
                </div>


            </div>
        </header>

    );
}

export default Header;