import React from 'react';
import { Link } from 'react-router-dom';

function Footer(props) {
    return (
        //         <!--==========================
        //     Footer
        //   ============================-->
        <footer id="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-8 col-md-6 footer-links">

                            <ul>
                                <li><a href="#">Copyright &copy; 2023 ISP ADBIN.   All rights reserved.</a></li>
                                <li>
                                    <Link to="/terms/#tnc"> Term and conditions</Link>
                                </li>
                                <li>
                                    <Link to="/privacy"> Privacy Policy</Link>
                                </li>

                            </ul>
                        </div>

                    </div>
                </div>
            </div>


        </footer>
        //<!-- #footer -->
    );
}

export default Footer;