import React from 'react';

function Services(props) {
    return (
        //     <!--==========================
        //     Services Section
        //   ============================-->
        <section id="services" className="section-bg">
            <div className="container">

                <header className="section-header">
                    <h3 className="text-center"><span className="style1">Solution</span> <span className="style2">for ISP</span></h3>
                </header>

                <div className="row">

                    <div className="col-md-12 col-lg-12 wow bounceInUp" data-wow-duration="1.4s">
                        <img src={process.env.PUBLIC_URL + "assets/img/solution-01.jpg"} className="img-fluid d-none d-lg-block" alt="" />
                        <img src={process.env.PUBLIC_URL + "assets/img/solution-02.jpg"} className="img-fluid d-xl-none d-lg-none" alt="" />
                    </div>

                </div>
                <div className="row">
                </div>

            </div>
        </section>
        //<!-- #services -->
    );
}

export default Services;