
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './Components/Footer';
import Header from './Components/Header';
import Intro from './Components/Intro';
import Main from './Components/Main';
import PrivacyPolicy from './Components/PrivacyPolicy';
import TermsAndConditions from './Components/TermsAndConditions';


function App() {
  return (
    <div>
      <Header />
      <Intro />
      <main id='main'>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsAndConditions />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
