import React from 'react';

function ContactUs(props) {
    return (
        <section id="contact">
            <div className="container">

                <div className="row wow fadeInUp">

                    <div className="col-lg-6">
                        <div className="section-header">
                            <h3 className="text-center"><span className="style1">Contact</span> <br /><span className="style2">Us</span></h3>

                        </div>
                        <div>
                            <p>
                                <span style={{ fontFamily: "HelveticaNeueLTPro-Bd" }}>Address : </span> <br />
                                A-303 ,3rd Floor,Royal Garden CHS,<br />
                                Dr Annie Besant Road, Besides Atria Mall,<br />
                                Worli, Mumbai 400018<br />
                                <span style={{ fontFamily: "HelveticaNeueLTPro-Bd" }}>Contact No. : </span>  9867555207
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactUs;