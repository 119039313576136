import React from 'react';


function Intro(props) {
    return (
        //     <!--==========================
        //     Intro Section
        //   ============================-->
        <section id="intro" className="clearfix">
            <div className="container">

                <div className="intro-img wow fadeInUp">
                    <img src={process.env.PUBLIC_URL + "assets/img/intro-img.jpg"} alt="" className="img-fluid" />
                </div>

                <div className="intro-info wow fadeInUp">
                    <img src={process.env.PUBLIC_URL + "assets/img/intro-img02.jpg"} alt="" className="img-fluid" />
                    <h2><span>ISP ADBIN </span> is integrated Customer Relationship Management for ISPs.</h2>
                </div>

            </div>
        </section>
    );
}

export default Intro;