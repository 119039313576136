import React from 'react';

const WhoUs = () => {
    return (
        <section id="about">
            <div className="container">



                <div className="row about-container">

                    <div className="col-lg-5 background order-1 wow fadeInUp">
                        <img src={process.env.PUBLIC_URL + "assets/img/about-img.jpg"} className="img-fluid" alt="" />
                    </div>

                    <div className="col-lg-7 content order-2 wow fadeInUp">
                        <header className="section-header">
                            <h3> What is <br /><span className="style1">ISP AD</span><span className="style2">BIN</span>?</h3>
                        </header>

                        <p><strong>ISP ADBIN</strong> is an ISP management software framework created for Internet service providers and network administrators.</p>
                        <p><strong>ISP ADBIN</strong> provides many useful functions such as Customized Data Packages, Customer Billing & Payment tracking, Detail Reports, Payment Gateway Integration,  OTP authentication, CRM & Helpdesk, Wi-Fi Hotspot Management, SMS Gateway Integration, Generate PINs/Vouchers for new customers or renewals, IP or MAC Authentication, everything that saves your time and makes your business a success.</p>
                    </div>


                </div>

            </div>
        </section>
        //<!-- #about -->
    );
};

export default WhoUs;